import React from "react"

import clsx from "clsx"
import Image, { StaticImageData } from "next/image"

import SwitzerTypography from "../SwitzerTypography"

export type Testimony = {
  name: string
  position: string
  image: StaticImageData | string
  message: string
  key: ("schoolLanding" | "itoLanding")[]
}

const TestimonialCard = ({ item }: { item: Testimony }) => {
  const { image, name, position, message } = item
  return (
    <div
      className={clsx(
        "px-2 py-3 rounded-lg sm:w-[202px] w-full bg-white flex flex-col justify-between shadow-[0px_6px_15px_0px_rgba(0,0,0,0.05)] sm:m-2 m-0"
      )}
    >
      <SwitzerTypography
        className="mb-2 !text-ito-midnight"
        fontFamily="Switzer Regular"
        variant="body"
      >
        {message}
      </SwitzerTypography>
      <div className="flex gap-1">
        <Image
          alt="profile-img"
          className="object-contain w-6 h-6 rounded-full shrink-0"
          height={48}
          src={image}
          width={48}
        />
        <div>
          <SwitzerTypography
            className="!text-ito-midnight"
            fontFamily="Switzer Bold"
            variant="strong"
          >
            {name}
          </SwitzerTypography>
          <SwitzerTypography
            color="onSurface.500"
            fontFamily="Switzer Regular"
            variant="smallBody"
          >
            {position}
          </SwitzerTypography>
        </div>
      </div>
    </div>
  )
}

export default TestimonialCard
