import React from "react"

import { Container, theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import Image from "next/image"
import EducationUAE from "public/assets/ito/featured-in/education-uae.webp"
import HansArticle from "public/assets/ito/featured-in/hans-article.webp"
// import FinancialExpressIndia from "public/assets/ito/featured-in/localised/india/financial-express.webp"
import SmeStreetArticleIndia from "public/assets/ito/featured-in/localised/india/sme-street.webp"
// import FinancialExpressInternational from "public/assets/ito/featured-in/localised/international/financial-express.webp"
import SmeStreetArticleInternational from "public/assets/ito/featured-in/localised/international/sme-street.webp"
import News1 from "public/assets/ito/featured-in/new-channels/news_1.webp"
import News2 from "public/assets/ito/featured-in/new-channels/news_2.webp"
import News3 from "public/assets/ito/featured-in/new-channels/news_3.webp"
import News4 from "public/assets/ito/featured-in/new-channels/news_4.webp"
import News5 from "public/assets/ito/featured-in/new-channels/news_5.webp"
import News6 from "public/assets/ito/featured-in/new-channels/news_6.webp"
import News7 from "public/assets/ito/featured-in/new-channels/news_7.webp"
import News8 from "public/assets/ito/featured-in/new-channels/news_8.webp"
import News9 from "public/assets/ito/featured-in/new-channels/news_9.webp"
import News10 from "public/assets/ito/featured-in/new-channels/news_10.webp"
import News11 from "public/assets/ito/featured-in/new-channels/news_11.webp"
import News12 from "public/assets/ito/featured-in/new-channels/news_12.webp"
import News13 from "public/assets/ito/featured-in/new-channels/news_13.webp"
import News14 from "public/assets/ito/featured-in/new-channels/news_14.webp"
import News15 from "public/assets/ito/featured-in/new-channels/news_15.webp"
import News16 from "public/assets/ito/featured-in/new-channels/news_16.webp"
import News17 from "public/assets/ito/featured-in/new-channels/news_17.webp"
import News18 from "public/assets/ito/featured-in/new-channels/news_18.webp"
import News19 from "public/assets/ito/featured-in/new-channels/news_19.webp"
import News20 from "public/assets/ito/featured-in/new-channels/news_20.webp"
import News21 from "public/assets/ito/featured-in/new-channels/news_21.webp"
import News22 from "public/assets/ito/featured-in/new-channels/news_22.webp"
import News23 from "public/assets/ito/featured-in/new-channels/news_23.webp"
import News24 from "public/assets/ito/featured-in/new-channels/news_24.webp"
import News25 from "public/assets/ito/featured-in/new-channels/news_25.webp"
import News26 from "public/assets/ito/featured-in/new-channels/news_26.webp"
import News27 from "public/assets/ito/featured-in/new-channels/news_27.webp"
import News28 from "public/assets/ito/featured-in/new-channels/news_28.webp"
import News29 from "public/assets/ito/featured-in/new-channels/news_29.webp"
import News30 from "public/assets/ito/featured-in/new-channels/news_30.webp"
import News31 from "public/assets/ito/featured-in/new-channels/news_31.webp"
import News32 from "public/assets/ito/featured-in/new-channels/news_32.webp"
import News33 from "public/assets/ito/featured-in/new-channels/news_33.webp"

import SlidingBanner from "components/shared/SlidingBanner"

import { itoPageSections } from "../NavigationSection"
import SwitzerTypography from "../SwitzerTypography"

import ImgContainer, { ImageContainerProps } from "./ImgContainer"

const newsChannels = [
  News1,
  News2,
  News3,
  News4,
  News5,
  News6,
  News7,
  News8,
  News9,
  News10,
  News11,
  News12,
  News13,
  News14,
  News15,
  News16,
  News17,
  News18,
  News19,
  News20,
  News21,
  News22,
  News23,
  News24,
  News25,
  News26,
  News27,
  News28,
  News29,
  News30,
  News31,
  News32,
  News33,
]

const indianArticles: ImageContainerProps[] = [
  {
    alt: "hans-article",
    color: "blue",
    href: " https://www.thehansindia.com/hans/education-careers/international-teachers-olympiad-crosses-70000-registrations-from-teachers-of-90-countries-824529",
    src: HansArticle,
  },
  {
    alt: "sme-street-article",
    color: "red",
    href: "https://smestreet.in/global/south-east-asia/international-teachers-olympiad-2023-announced-3-lakh-teachers-to-register-from-10000-schools-globally/",
    src: SmeStreetArticleIndia,
  },
  {
    alt: "education-uae-article",
    color: "black",
    href: "https://www.education-uae.com/registrations-now-open-for-international-teachers-olympiad-2023/",
    src: EducationUAE,
  },
]

const internationalArticles: ImageContainerProps[] = [
  {
    alt: "education-uae-article",
    color: "black",
    href: "https://www.education-uae.com/registrations-now-open-for-international-teachers-olympiad-2023/",
    src: EducationUAE,
  },
  {
    alt: "sme-street-article",
    color: "red",
    href: "https://smestreet.in/global/south-east-asia/international-teachers-olympiad-2023-announced-3-lakh-teachers-to-register-from-10000-schools-globally/",
    src: SmeStreetArticleInternational,
  },
  {
    alt: "hans-article",
    color: "blue",
    href: " https://www.thehansindia.com/hans/education-careers/international-teachers-olympiad-crosses-70000-registrations-from-teachers-of-90-countries-824529",
    src: HansArticle,
  },
]

const FeaturedIn = ({
  spacing,
  countryCode,
}: {
  spacing: string
  countryCode?: string
}) => {
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const articles = countryCode === "IN" ? indianArticles : internationalArticles

  return (
    <div className={spacing} id={itoPageSections.FEATURED_IN}>
      <div className="py-3 mb-1">
        <SwitzerTypography
          className="text-center mb-4 !text-ito-midnight"
          fontFamily="Switzer Bold"
          variant="title2"
        >
          Featured In
        </SwitzerTypography>
        <SlidingBanner
          // eslint-disable-next-line react/no-unstable-nested-components
          component={data => (
            <div className="mx-1">
              <Image
                alt="img"
                className="object-contain h-4 w-fit"
                src={data}
              />
            </div>
          )}
          images={newsChannels}
          repeatImageTimes={1}
          speedTime={100}
        />
      </div>
      <Container fullWidth={isXsDown}>
        <div className="flex flex-col justify-center gap-2 px-0 mt-2 sm:px-2 sm:gap-3 sm:flex-row">
          {articles.map((item, index) => (
            <ImgContainer key={index} {...item} />
          ))}
        </div>
      </Container>
    </div>
  )
}

export default FeaturedIn
