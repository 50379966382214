import React from "react"

import { Theme, Typography, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import Image from "next/image"
import ChatMobile from "public/assets/ito/contact-us/chat-mobile.webp"
import ChatTab from "public/assets/ito/contact-us/chat-tab.webp"
import ChatWeb from "public/assets/ito/contact-us/chat-web.webp"
import { createUseStyles, useTheme } from "react-jss"

import { TRACKING_DATA } from "utils/googleAnalytics"

const useStyles = createUseStyles(() => ({
  cardBody: {
    wordBreak: "break-word",
  },
}))

type CardProps = {
  title: string
  body: string
  href: string
  highlight?: boolean
  icon: React.ReactNode
}

const Card = ({ title, body, icon, highlight = false, href }: CardProps) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const getChatIcon = (): any => {
    if (isXsDown) {
      return ChatMobile
    }
    if (isSmDown) {
      return ChatTab
    }
    return ChatWeb
  }
  return (
    <a
      className={clsx(
        "p-2 flex gap-2 cursor-pointer rounded-lg shadow-[0px_6px_15px_rgba(0,0,0,0.05)] text-ito-midnight no-underline w-full",
        {
          "relative bg-ito-mint-100 border-2 border-ito-mint-600": highlight,
          "bg-white": !highlight,
        }
      )}
      data-feature={TRACKING_DATA.feature.ito}
      data-medium={title.toLowerCase()}
      data-purpose={TRACKING_DATA.purpose.contactUs}
      data-source={TRACKING_DATA.source.itoLandingPage}
      data-trackingid="ito-contact-us"
      href={href}
    >
      <div>{icon}</div>
      <div>
        <Typography color="onSurface.500" variant="smallBody">
          {title}
        </Typography>
        <Typography className={classes.cardBody} variant="strongSmallBody">
          {body}
        </Typography>
      </div>
      {highlight && (
        <div className="absolute max-w-[96px] md:-top-2 md:left-[-100px] sm:right-auto sm:left-0 -top-7 right-0">
          <Image alt="chat-with-me" src={getChatIcon()} />
        </div>
      )}
    </a>
  )
}

export default Card
