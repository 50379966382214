import MichelleFernandez from "public/assets/ito/reward-report/about-reports/michelle-fernandez.webp"
import MuktaZite from "public/assets/ito/reward-report/about-reports/mukta-zite.webp"
import NeneIbezim from "public/assets/ito/reward-report/about-reports/nene-ibezim.webp"
import PriyaRaushan from "public/assets/ito/reward-report/about-reports/priya-raushan.webp"
import RijuSingh from "public/assets/ito/reward-report/about-reports/riju-singh.webp"
import ElizabethStephan from "public/assets/ito/school/landing-page/teachers/elizabeth-stephan.webp"
import NorphanAddelfattah from "public/assets/ito/school/landing-page/teachers/norhan-abdelfattah.webp"

import { TestimonialType } from "../RatingAndReviews/testimonials"

import { Testimony } from "./TestimonialCard"

export const data: Testimony[] = [
  {
    name: "Riju Singh",
    position: "Aspiring Teacher, India",
    image: RijuSingh,
    key: ["itoLanding"],

    message:
      "My personalised report for ITO 2022 was incredibly helpful. It provided a clear understanding of my performance and where I stand not only in India but also on an international level.",
  },
  {
    name: "Nene Ibezim",
    position: "Teacher Coach, Nigeria",
    image: NeneIbezim,
    key: ["itoLanding", "schoolLanding"],

    message:
      "The personalised Olympiad report left me amazed by its level of detail & feedback. It has inspired me to be intentional about my growth. The Olympiad gave me a chance to track my progress as a Teacher.",
  },
  {
    name: "Priya Raushan",
    position: "School Teacher, UAE",
    image: PriyaRaushan,
    key: ["itoLanding", "schoolLanding"],

    message:
      "When I was at the airport, I eagerly downloaded my report. It stated I was in the top 1 percentile in ITO 2022. I can’t even express how happy it made me.",
  },
  {
    name: "Mukta",
    position: "PRT Teacher, India",
    image: MuktaZite,
    key: ["itoLanding"],

    message:
      "The International Teachers’ Olympiad challenged me to think beyond theoretical knowledge. Moreover, the report given was my personal favourite. It gave me insight into how a teacher actually should be.",
  },
  {
    name: "Michelle Fernandez",
    position: "English Teacher, India",
    image: MichelleFernandez,
    key: ["itoLanding"],

    message:
      "The personalised report was insightful. I got detailed feedback & the opportunity to gauge my teaching abilities through the ITO 2022. It was great.",
  },
  {
    name: "Norhan Abdelfattah",
    position: "School Teacher, Dubai, UAE",
    image: NorphanAddelfattah,
    key: ["schoolLanding"],
    message:
      "I saw the Olympiad as a way to assess my skills in the field of teaching. I wanted to see how far I had progressed in my studies & professional development.",
  },
  {
    name: "Elizabeth Stephan",
    position: "School Teacher, South Africa",
    image: ElizabethStephan,
    key: ["schoolLanding"],
    message:
      "Most of our Leap teachers registered and took the test. It was an exciting and surprising journey.",
  },
]
export const itoLandingData: TestimonialType[] = [
  {
    testimonial: `"Everything is <span>relevant</span> for teachers to reflect on the real-life situation in the <span>teaching process.</span> I already <span>recommended</span> Suraasa to my friends & family. I hope that more teachers join in 2023."`,
    name: "Brigette Cuntapay",
    rating: 4.75,
  },

  {
    testimonial: `"All in all, it was a <span>great</span> experience and learning for me. The feedback report will help me to <span>reflect</span> on how to be a better individual & a <span>better professional.</span>"`,
    name: "Elveera Rodrigues",
    rating: 4.5,
  },

  {
    testimonial: `"The personalised Olympiad report left me <span>amazed</span> by its level of detail & feedback. It has inspired me to be intentional about my <span>growth.</span> The Olympiad gave me a chance to <span>track my progress</span> as a Teacher."`,
    name: "Nene Ibezim",
    image: NeneIbezim.src,
    rating: 4.75,
  },
  {
    testimonial: `"My personalised report for ITO 2022 was <span>incredibly helpful.</span> It provided a clear understanding of my performance and <span>where I stand</span> not only in India but also on an <span>international level.</span>"`,
    name: "Riju Singh",
    image: RijuSingh.src,
    rating: 4.5,
  },

  {
    testimonial: `"Everything about the entire experience <span>met my expectations</span> — the Olympiad, the <span>report</span>, the plans and the masterclasses."`,
    name: "Maxim Digbehi",
    rating: 5,
  },

  {
    testimonial: `"The personalised report was <span>insightful.</span> I got <span>detailed feedback</span> & the opportunity to gauge my teaching abilities through the ITO 2022. It was <span>great.</span>"`,
    name: "Michelle Fernandez",
    image: MichelleFernandez.src,
    rating: 5,
  },

  {
    testimonial: `"When I was at the airport, I eagerly downloaded my report. It stated I was in the <span>top 1 percentile</span> in ITO 2022. I can’t even express how <span>happy</span> it made me."`,
    name: "Priya Raushan",
    image: PriyaRaushan.src,
    rating: 4.75,
  },
  {
    testimonial: `"The content and its delivery were both <span>remarkable.</span> I am <span>very happy</span> with my overall ITO experience."`,
    name: "Azima Aamir",
    rating: 4.5,
  },

  {
    testimonial: `"The report is <span>much needed</span> for refining our teaching skills. While knowing what to teach is already clear, the real challenge lies in how to <span>teach effectively.</span>"`,
    name: "Keneile -ü Suokhrie",
    rating: 4.5,
  },
  {
    testimonial: `"The International Teachers’ Olympiad challenged me to think <span>beyond theoretical knowledge.</span> Moreover, the report given was my <span>personal favourite.</span> It gave me <span>insight</span> into how a teacher actually should be."`,
    name: "Mukta",
    image: MuktaZite.src,
    rating: 4.5,
  },
  {
    testimonial: `"The resources provided after the Olympiad offered a <span>comprehensive understanding</span> of pedagogy & strategies. As a teacher, these materials have been <span>incredibly beneficial.</span>"`,
    name: "Smita Bnair",
    rating: 4.5,
  },
  {
    testimonial: `"It was a <span>wonderful</span> experience, particularly due to its <span>diagnostic nature.</span> Looking forward to <span>progressing</span> with the Suraasa approach!"`,
    name: "Kumudini Kapadia",
    rating: 4.0,
  },
  {
    testimonial: `"The ITO report is truly a <span>self-reflective</span> exercise that enhances a positive <span>challenge-oriented spirit.</span> Great thing for <span>Teachers!</span>"`,
    name: "Ravneet Kaur",
    rating: 4.75,
  },
  {
    testimonial: `"I would like to <span>appreciate</span> the team who worked all through to make the report. It definitely <span>brings positive changes</span> to teachers lives and instill a <span>sense of pride.</span>"`,
    name: "Sharmistha Roy",
    rating: 4.75,
  },
  {
    testimonial: `"It's a <span>great</span> resource that helped me learn how well I can put myself in a place where I feel proud of myself being a <span>Teacher.</span>"`,
    name: "Sabitha Matla",
    rating: 4.5,
  },
  {
    testimonial: `"<span>A big thank you</span> to Team Suraasa for guiding teachers to <span>self-reflect</span> and providing this <span>wonderful</span> report for improvement. <span>Hats off</span> to you!"`,
    name: "Nupur Rajkumar",
    rating: 5,
  },
  {
    testimonial: `"This self-assessment is beneficial for <span>introspection</span> and serves as a <span>valuable tool</span> for teachers to <span>stay updated.</span>"`,
    name: "Shruti Pandey",
    rating: 4.75,
  },
  {
    testimonial: `"It's a <span>yardstick</span> to measure the <span>inbuilt qualities</span> we had and further how to <span>mould ourselves</span> to confront the dynamic world."`,
    name: "Dwaram Jagannadha",
    rating: 5,
  },
  {
    testimonial: `"Despite being a <span>teacher</span>, I realised there were many areas I should have known earlier. This report will help me <span>rectify my mistakes</span> and <span>learn</span> extensively."`,
    name: "Swati Ritesh",
    rating: 5,
  },
]
