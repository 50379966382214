import React from "react"

import clsx from "clsx"
import { createUseStyles } from "react-jss"

import { Clock, Page, QuestionMark } from "iconoir-react"

import { itoSections } from "utils/constants"

import SwitzerTypography from "../SwitzerTypography"

const useStyles = createUseStyles(theme => ({
  title2: {
    fontFamily: "Switzer Bold",
    fontSize: 32,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "-0.64px",
  },
  infoIcon: {
    border: "1px solid rgba(255, 255, 255, 0.80)",
    backdropFilter: "blur(6px)",
  },
  container: {
    backgroundColor: "#ffffff",
    backgroundImage: `radial-gradient(at 96% 60%, rgba(116, 63, 245, 0.175) , transparent 50%), 
    radial-gradient(at 100% 100%, rgba(234, 89, 202, 0.15) 0, transparent 59%),
    radial-gradient(at 55% 78%, rgba(235, 72, 63, 0.15) 0, transparent 37%)`,
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `radial-gradient(circle at 80% 90%, rgba(116, 63, 245, 0.15) 0%, transparent 50%),radial-gradient(circle at 40% 90%, rgba(234, 89, 202, 0.15) 0, transparent 50%),radial-gradient(circle at 0% 90%, rgba(235, 72, 63, 0.15) 0, transparent 50%)`,
    },
  },
}))
type IconProps = {
  icon: any
  title: string
  body: string
}

const InfoItem = ({ icon, title, body }: IconProps) => {
  const classes = useStyles()
  return (
    <div className="flex gap-1 mb-2 sm:mb-0">
      <div
        className={clsx(
          classes.infoIcon,
          "bg-ito-midnight px-1 py-1.25 text-white rounded-lg"
        )}
      >
        {icon}
      </div>
      <div>
        <SwitzerTypography
          className="!text-ito-midnight"
          fontFamily="Switzer Regular"
          variant="strongSmallBody"
        >
          {title}
        </SwitzerTypography>
        <SwitzerTypography
          className="!text-ito-midnight"
          fontFamily="Switzer Regular"
          variant="smallBody"
        >
          {body}
        </SwitzerTypography>
      </div>
    </div>
  )
}

type Props = {
  isSchool?: boolean
}

const Syllabus = (props?: Props) => {
  const classes = useStyles()
  return (
    <section
      className={clsx(
        classes.container,
        "rounded-3xl p-3 border border-[#e2e8f080]"
      )}
      id={itoSections.SYLLABUS}
    >
      <SwitzerTypography
        className="!text-ito-midnight mb-2"
        fontFamily="Switzer Bold"
        variant="title1"
      >
        Syllabus & Pattern
      </SwitzerTypography>
      <SwitzerTypography
        className="!text-ito-midnight mb-4"
        fontFamily="Switzer Regular"
        variant="largeBody"
      >
        All questions would be based around teaching skills and pedagogy. The
        Olympiad is not subject specific. Any teacher can attempt the Olympiad
        irrespective of the subject they teach.
      </SwitzerTypography>

      <div className="flex gap-4 mb-4">
        <div className="flex flex-wrap gap-5">
          <InfoItem body="60 minutes" icon={<Clock />} title="Time" />
          <InfoItem body="50" icon={<Page />} title="Questions" />
          <InfoItem body="MCQ" icon={<QuestionMark />} title="Question Type" />
        </div>
      </div>

      <span className={clsx(classes.title2, "mb-2 text-ito-midnight block")}>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {props?.isSchool
          ? "No Prior Preparation Required"
          : "How do I prepare?"}
      </span>
      <SwitzerTypography
        className="!text-ito-midnight mb-2"
        fontFamily="Switzer Regular"
        variant="largeBody"
      >
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {props?.isSchool
          ? "Your teachers don't need to spend extra time preparing for this Olympiad. It expertly assesses their existing pedagogical knowledge and skills, providing you with an accurate analysis of their competencies."
          : "The purpose of the International Teachers’ Olympiad is to assess your existing pedagogical knowledge and skills. No prior preparation is required. This enables you to get unbiased and accurate expert recommendations in your report."}
      </SwitzerTypography>
    </section>
  )
}

export default Syllabus
