import React from "react"

import { sub } from "date-fns"

import { Calendar, Clock, Computer } from "iconoir-react"

import { Country } from "api/resources/global/types"
import customTheme from "utils/customTheme"
import { formatDate } from "utils/helpers"

import SwitzerTypography from "../SwitzerTypography"

// const getExamTime = (country: Country | null) => {
//   switch (country?.isoCode) {
//     case "IN":
//       return "4:30 PM"
//     case "AE":
//       return "3:00 PM"
//     case "GB":
//       return "12:00 PM"

//     default:
//       return "12:00 PM (UK Time)"
//   }
// }
const svgProps = {
  color: customTheme.iris[700],
  height: 18,
  width: 18,
}

const getExamTime = () => {
  // If I'm in India, then this value would be 5.5
  const userTimezoneOffset = -new Date().getTimezoneOffset() / 60

  const ranges = [
    {
      min: -7,
      max: -3,
      centre: -5,
      time: Date.UTC(2023, 9, 7, 14, 0, 0),
    },
    {
      min: -2.75,
      max: 1.75,
      centre: 0,
      time: Date.UTC(2023, 9, 7, 14, 0, 0),
    },
    {
      min: 2,
      max: 5.75,
      centre: 5.5,
      time: Date.UTC(2023, 9, 7, 17, 0, 0),
    },
    {
      min: 6,
      max: 12,
      centre: 12,
      time: Date.UTC(2023, 9, 7, 17, 0, 0),
    },
  ]

  for (const range of ranges) {
    if (range.min <= userTimezoneOffset && range.max >= userTimezoneOffset) {
      const time = sub(range.time, { minutes: range.centre * 60 })
      return formatDate(time.toISOString(), "h:mm a")
    }
  }

  return "2:00 PM (UK Time)"
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Info = ({ country }: { country: Country | null | undefined }) => {
  const boxData = [
    {
      title: "Date",
      data: country?.isoCode === "US" ? "Oct 7, 2023" : "7th Oct 2023",
    },
    { title: "Time", data: getExamTime() },
    { title: "Mode", data: "Online" },
  ]

  const getIcon = (item: string) => {
    if (item === "Date") {
      return <Calendar {...svgProps} />
    }
    if (item === "Time") {
      return <Clock {...svgProps} />
    }
    return <Computer {...svgProps} />
  }

  return (
    <div className="flex flex-col w-full gap-2 md:flex-col sm:flex-row">
      {boxData.map((item, index) => (
        <div
          className="flex-grow p-3 flex flex-col gap-1.5 bg-white rounded-2xl border border-[#e2e8f080]"
          key={index}
        >
          <div className="flex gap-1">
            <div className="flex items-center justify-center w-4 h-4 rounded-full bg-ito-iris-200">
              {getIcon(item.title)}
            </div>
            <SwitzerTypography
              className="!text-ito-midnight"
              fontFamily="Switzer Regular"
              variant="subtitle1"
            >
              {item.title}
            </SwitzerTypography>
          </div>
          <SwitzerTypography
            className="!text-ito-midnight"
            fontFamily="Switzer Bold"
            variant="title1"
          >
            {item.data}
          </SwitzerTypography>
        </div>
      ))}
    </div>
  )
}

export default Info
