import React from "react"

const WhatsappSvg = ({ className }: { className?: string }) => (
  <svg
    className={className}
    fill="none"
    height="20"
    viewBox="0 0 21 20"
    width="21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5354_17078)">
      <path
        clipRule="evenodd"
        d="M17.4915 2.90417C15.622 1.03232 13.1357 0.000949696 10.4865 0C5.02768 0 0.584998 4.44221 0.583098 9.90249C0.582149 11.648 1.03848 13.3518 1.90508 14.8533L0.5 19.9854L5.74992 18.6083C7.1963 19.3976 8.82503 19.813 10.4823 19.8135H10.4865C15.9444 19.8135 20.3876 15.3708 20.3895 9.91055C20.3904 7.26425 19.3614 4.7765 17.4915 2.90465V2.90417ZM10.4865 18.1411H10.4832C9.00641 18.1407 7.55768 17.7436 6.29364 16.9939L5.99305 16.8154L2.87757 17.6325L3.70903 14.5949L3.51339 14.2834C2.68952 12.9729 2.25409 11.4581 2.25504 9.90298C2.25694 5.36484 5.94936 1.67241 10.4899 1.67241C12.6884 1.67336 14.755 2.53047 16.3091 4.08655C17.8633 5.64216 18.7185 7.71061 18.7176 9.90961C18.7156 14.4482 15.0233 18.1407 10.4865 18.1407V18.1411ZM15.0014 11.9766C14.754 11.8527 13.5375 11.2544 13.3104 11.1718C13.0835 11.0891 12.9187 11.0478 12.7539 11.2957C12.5892 11.5436 12.1148 12.1011 11.9704 12.2658C11.8261 12.4311 11.6817 12.4515 11.4343 12.3275C11.187 12.2036 10.3897 11.9424 9.44425 11.0996C8.70873 10.4433 8.21199 9.63326 8.06768 9.38536C7.92331 9.13751 8.05248 9.00359 8.17591 8.88061C8.28701 8.7695 8.42331 8.59144 8.54724 8.44707C8.67121 8.30271 8.71204 8.19923 8.79464 8.03442C8.87729 7.86917 8.83597 7.72486 8.77425 7.60088C8.71248 7.47696 8.21773 6.25895 8.01116 5.7637C7.81027 5.28127 7.60613 5.3468 7.45464 5.33872C7.31027 5.3316 7.14552 5.33018 6.98027 5.33018C6.81503 5.33018 6.54718 5.39191 6.32022 5.63978C6.09326 5.88762 5.45409 6.48641 5.45409 7.70392C5.45409 8.92144 6.34066 10.0986 6.46458 10.2639C6.58851 10.4291 8.20961 12.9282 10.6917 14.0004C11.2819 14.2554 11.743 14.4078 12.1024 14.5218C12.6951 14.7103 13.2345 14.6838 13.6609 14.6201C14.1362 14.5489 15.1249 14.0213 15.3309 13.4434C15.537 12.8655 15.537 12.3698 15.4753 12.2667C15.4136 12.1637 15.2483 12.1015 15.0009 11.9776L15.0014 11.9766Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_5354_17078">
        <rect
          fill="white"
          height="20"
          transform="translate(0.5)"
          width="19.8895"
        />
      </clipPath>
    </defs>
  </svg>
)

export default WhatsappSvg
