import React from "react"

import clsx from "clsx"
import Image, { StaticImageData } from "next/image"

export type ImageContainerProps = {
  src: StaticImageData
  alt: string
  color: "red" | "purple" | "blue" | "yellow" | "black"
  href: string
  className?: string
}

const ImgContainer = ({
  src,
  alt,
  color,
  href,
  className,
}: ImageContainerProps) => (
  <a
    className={clsx(
      "overflow-hidden relative pt-2 px-2 w-full sm:w-[235px] md:w-[306px]  sm:rounded-lg rounded-0 flex justify-center",
      className,
      {
        "bg-[#D0484D]": color === "red",
        "bg-[#962F8A]": color === "purple",
        "bg-[#085BA3]": color === "blue",
        "bg-[#FFCA43]": color === "yellow",
        "bg-[#6A686A]": color === "black",
      }
    )}
    href={href}
    rel="noreferrer"
    target="_blank"
  >
    <Image
      alt={alt}
      className="mx-auto max-h-[290px] rounded-t-lg object-contain"
      src={src}
    />
  </a>
)

export default ImgContainer
