import React from "react"

import useDetectCountry from "utils/hooks/useDetectCountry"

import SwitzerTypography from "../SwitzerTypography"

const AboutTheOrganizer = () => {
  const country = useDetectCountry()

  const formatNumber = () => {
    if (country?.isoCode === "IN") {
      return "4,50,000"
    }
    return "450,000"
  }

  return (
    <div className="p-2 rounded-2xl bg-surface-100">
      <SwitzerTypography
        className="!text-ito-midnight mb-1"
        fontFamily="Switzer Bold"
        variant="largeBody"
      >
        About the Organiser
      </SwitzerTypography>
      <SwitzerTypography
        className="!text-ito-midnight"
        fontFamily="Switzer Regular"
      >
        The Olympiad is being organised by Suraasa, an international
        organization that helps teachers achieve greater professional and
        financial growth in their careers. The team has trained teachers from
        45+ nationalities, conducted over {formatNumber()}+ teacher assessments,
        and delivered over 125+ million minutes of learning in the past few
        years.
      </SwitzerTypography>
    </div>
  )
}

export default AboutTheOrganizer
