import React from "react"

import clsx from "clsx"
import ArrowSvg from "public/assets/ito/navigation-section/arrow"
import { createUseStyles } from "react-jss"

import customTheme from "utils/customTheme"

import SwitzerTypography from "../SwitzerTypography"

type Props = {
  title?: string
  variant: "peach" | "bubblegum" | "lavender" | "salmon" | "mint" | "iris"
  href: string
}

const useStyles = createUseStyles(() => ({
  "@keyframes sidewise-slide": {
    "0%": { opacity: 1, transform: "translateX(0px) scale(1)" },
    "25%": { opacity: 0, transform: "translateX(5px) scale(0.9)" },
    "26%": { opacity: 0, transform: "translateX(-5px) scale(0.9)" },
    "55%": { opacity: 1, transform: "translateX(0px) scale(1)" },
  },
  container: {
    "&:hover": {
      "& .arrow": {
        animationName: "$sidewise-slide",
        animationDuration: "1s",
        animationIterationCount: "1",
        animationTimingFunction: "linear",
      },
    },
  },
  peach: {
    background: customTheme.peach[100],
    color: customTheme.peach[900],
    "& .arrowBorder": {
      borderColor: customTheme.peach[900],
    },
  },
  bubblegum: {
    background: customTheme.peach[100],
    color: customTheme.peach[900],
    "& .arrowBorder": {
      borderColor: customTheme.peach[900],
    },
  },
  lavender: {
    background: customTheme.lavender[100],
    color: customTheme.lavender[900],
    "& .arrowBorder": {
      borderColor: customTheme.lavender[900],
    },
  },
  salmon: {
    background: customTheme.salmon[100],
    color: customTheme.salmon[900],
    "& .arrowBorder": {
      borderColor: customTheme.salmon[900],
    },
  },
  mint: {
    background: customTheme.mint[100],
    color: customTheme.mint[900],
    "& .arrowBorder": {
      borderColor: customTheme.mint[900],
    },
  },
  iris: {
    background: customTheme.iris[100],
    color: customTheme.iris[900],
    "& .arrowBorder": {
      borderColor: customTheme.iris[900],
    },
  },
}))

const Card = ({ title = "What is the Syllabus?", variant, href }: Props) => {
  const classes = useStyles()
  return (
    <a
      className={clsx(
        "w-full flex justify-between gap-1.25 sm:px-3 px-2 py-2 rounded-2xl",
        classes.container,
        classes[variant]
      )}
      href={`#${href}`}
      onClick={() => {
        if (href === "FAQ") {
          const element = document.getElementById("Attempt_ITO")
          if (element) {
            element.click()
          }
        }
      }}
    >
      <SwitzerTypography
        fontFamily="Switzer Regular"
        textAlign="left"
        variant="strong"
      >
        {title}
      </SwitzerTypography>
      <div
        className={clsx(
          "grid items-center justify-center w-[23px] h-[23px] border border-2 rounded-full arrowBorder shrink-0"
        )}
      >
        <ArrowSvg className="arrow" />
      </div>
    </a>
  )
}

export default Card
