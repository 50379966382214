const ArrowSvg = ({ className }: { className?: string }) => (
  <svg
    className={className}
    fill="currentColor"
    height="9"
    viewBox="0 0 10 9"
    width="10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.999349 4.35433H8.66602M8.66602 4.35433L5.31185 1.00016M8.66602 4.35433L5.31185 7.7085"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.725"
    />
  </svg>
)

export default ArrowSvg
