import ArchanaSingh from "public/assets/ito/school-leader/archana-singh.webp"
import DeepikaJaidas from "public/assets/ito/school-leader/deepika-jaidas.webp"
import FarzanaDohadwala from "public/assets/ito/school-leader/farzana-dohadwala.webp"
import GeetaVarshneya from "public/assets/ito/school-leader/geeta-varshneya.webp"
import ManishaWalia from "public/assets/ito/school-leader/manisha-walia.webp"
import SandeepHooda from "public/assets/ito/school-leader/sandeep-hooda.webp"
import SuhasJadhav from "public/assets/ito/school-leader/suhas-jadhav.webp"

import { Testimony } from "../ReapRewardReport/TestimonialCard"

export const data: Testimony[] = [
  {
    image: FarzanaDohadwala,
    message:
      "I am very excited. This is a very great concept at a very minimal cost. It gives you what you need to do to improve your teaching prowess.",
    name: "Farzana Dohadwala",
    key: ["schoolLanding", "itoLanding"],
    position: "Executive Chairman, Crimson Education",
  },
  {
    image: SandeepHooda,
    message:
      "Professional Development is crucial in today’s time. ITO enables teachers to get a sense of their existing teaching skills & analyse their effectiveness.",
    name: "Sandeep Hooda",
    key: ["schoolLanding", "itoLanding"],
    position: "CEO, Vega Schools",
  },
  {
    image: SuhasJadhav,
    message:
      "ITO enables teachers' skill-assessment, improves their competency, and provides insights into global teaching practices.",
    name: "Dr. Suhas Jadhav",
    key: ["itoLanding"],
    position: "Principal, SVDMS",
  },
  {
    image: ManishaWalia,
    message:
      "The International Teachers’ Olympiad is a great opportunity for educators to build their confidence & to grow & excel in their profession.",
    name: "Manisha Walia",
    key: ["itoLanding"],
    position: "Principal, Mega Mind School, India",
  },
  {
    image: DeepikaJaidas,
    message:
      "I highly recommend the Olympiad to all aspiring & practicing teachers. It will reignite your teaching passion & help you stay updated with the latest practices.",
    name: "Deepika Jaidas",
    key: ["itoLanding", "schoolLanding"],
    position: "Education Officer, S N Vidya Mandir School, India",
  },
  {
    image: GeetaVarshneya,
    message:
      "ITO focuses on skill application, which is lacking in traditional training. A valuable opportunity for those seeking to improve their skills through feedback.",
    name: "Geeta Varshneya",
    key: ["itoLanding", "schoolLanding"],
    position: "Director, Sriram Global School, India",
  },
  {
    image: ArchanaSingh,
    message:
      "ITO marks a shift in the educational space, giving teachers a chance to identify their competencies & connect with educators on a global scale.",
    name: "Archana Singh",
    key: ["itoLanding"],
    position: "Teacher Trainer, Delhi Public School",
  },
]
