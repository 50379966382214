import React from "react"

import clsx from "clsx"
import { createUseStyles } from "react-jss"

import customTheme from "utils/customTheme"

const useStyles = createUseStyles({
  "@keyframes horizontal": {
    from: {
      transform: "translate3d(0% , 0, 0)",
    },

    to: {
      transform: "translate3d(-50%, 0, 0)",
    },
  },
  "@keyframes vertical": {
    "0%": {
      transform: "translate3d(0% , 0%, 0)",
    },
    "100%": {
      transform: "translate3d(0, -50%, 0)",
    },
  },
  slider: {
    "& .slider-inner": {
      animation: "$horizontal linear infinite",
      animationDuration: "var(--speed)",
    },
    "& .slider-outer": {
      animation: "$horizontal linear infinite",
      animationDuration: "var(--speed)",
      animationDirection: "reverse",
    },
    "& .slider-up": {
      animation: "$vertical linear infinite",
      animationDuration: "var(--speed)",
      animationDirection: "reverse",
    },
    "& .slider-down": {
      animation: "$vertical linear infinite",
      animationDuration: "var(--speed)",
    },
  },

  name: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12.8474px",
    lineHeight: "16px",
    letterSpacing: "-0.04em",
    color: customTheme.midnight,
  },
})

type SlidingBannerType<T> = {
  images: T[]
  component: (data: T, index: number) => JSX.Element
  className?: string
  reverseAnimation?: boolean
  direction?: "horizontal" | "vertical"
  repeatImageTimes?: number
  /**
   * in seconds
   */
  speedTime?: number
}

const SlidingBanner = <T,>({
  className: userClassName,
  images,
  repeatImageTimes = 1,
  reverseAnimation,
  speedTime = 80,
  component: customComponent,
  direction = "horizontal",
}: SlidingBannerType<T>) => {
  const classes = useStyles()

  return (
    <div
      className={clsx("overflow-hidden", classes.slider, userClassName)}
      style={
        {
          "--speed": `${speedTime}s`,
        } as React.CSSProperties
      }
    >
      <div
        className={clsx("flex", {
          "slider-outer h-full w-max gap-3":
            reverseAnimation && direction === "horizontal",
          "slider-inner h-full w-max gap-3":
            !reverseAnimation && direction === "horizontal",
          "slider-up flex-col": reverseAnimation && direction === "vertical",
          "slider-down flex-col": !reverseAnimation && direction === "vertical",
        })}
      >
        {Array(repeatImageTimes)
          .fill(0)
          .map(() => images.map((item, i) => customComponent(item, i)))}
      </div>
    </div>
  )
}

export default SlidingBanner
